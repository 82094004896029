<template>
  <div class="p-grid dashboard">

    <Toast/>
    <div class="p-col-12  p-md-4 center p-m-auto" >
      <div class="card progress-card center" v-show="!loading1" >

        <p class="time">{{ time }}</p>

        <Button icon="pi pi-star" class="p-button-rounded p-mr-2 p-mb-2 btn-start" v-show="!running" :disabled='isDisabled'
                @click="startVideo"> Start
        </Button>
        <Button icon="pi pi-star" class="p-button-rounded p-mr-2 p-mb-2 btn-start p-button-danger" v-show="running" :disabled='isDisabled'
                @click="stopVideo"> Stop
        </Button>
      </div>

      <div class="card progress-card center" v-show="loading1" >
        <h5>Chargement...</h5>
        <div class="p-grid">
          <div class="p-col">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

//import axios from 'axios'
import axios from "axios";
import https from "https";

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';


export default {
  data() {
    return {
      time: '00:00:00.000',
      running: false,
      timeBegan: null,
      timeStopped: null,
      stoppedDuration: 0,
      started: null,
      nb_cam: 0,
      version: 0,
      ext_record: 0,
      pwd: '',
      login: '',
      server: '',
      isDisabled: false,
      loading1: true,
    }
  },
  productService: null,
  created() {
  },
  mounted() {
    if (localStorage.nb_cam) {
      this.nb_cam = localStorage.nb_cam;
    }
    if (localStorage.pwd) {
      this.pwd = localStorage.pwd;
    }
    if (localStorage.login) {
      this.login = localStorage.login;
    }
    if (localStorage.server) {
      this.server = localStorage.server;
    }
    if (localStorage.version) {
      this.version = localStorage.version;
    }
    if (localStorage.ext_record) {
      this.ext_record = parseInt(localStorage.ext_record);
    }

    this.isStart();

  },

  methods: {
    lockRunning() {
      if (this.timeBegan === null) {
        this.timeBegan = new Date();
      }
      let currentTime = new Date()
          , timeElapsed = new Date(currentTime - this.timeBegan)
          , hour = timeElapsed.getUTCHours()
          , min = timeElapsed.getUTCMinutes()
          , sec = timeElapsed.getUTCSeconds()
          , ms = timeElapsed.getUTCMilliseconds()

      this.time =
          this.zeroPrefix(hour, 2) + ":" +
          this.zeroPrefix(min, 2) + ":" +
          this.zeroPrefix(sec, 2) + "." +
          this.zeroPrefix(ms, 3);
    },
    showWarn(summary, detail) {
      this.$toast.add({severity: 'warn', summary: summary, detail: detail, life: 3000});
    },
    showError(summary, detail) {
      this.$toast.add({severity: 'error', summary: summary, detail: detail, life: 5000});
    },
    isStart() {

      const opt = {
        httpsAgent: new https.Agent({
          rejectUnauthorized: false
        })
      }
      return axios.get(this.server + '/webapi/auth.cgi?api=SYNO.API.Auth&method=Login&version=' + this.version + '&account=' + this.login + '&passwd=' + this.pwd + '&session=SurveillanceStation&format=sid',opt).then(response => {
        if (response.status === 200) {
          let sid = response.data.data.sid;
          axios.get(this.server + '/webapi/entry.cgi?privCamType=3&version=' + this.nb_cam + '&blIncludeDeletedCam=false&streamInfo=false&api="SYNO.SurveillanceStation.Camera"&basic=true&method="List"&_sid=' + sid,opt).then(response_cam => {
            for (let i = 0; i < response_cam.data.data.cameras.length; i++) {
              if (response_cam.data.data.cameras[i].recStatus>0) {
                this.running = true;
                this.isDisabled=false
                navigator.setAppBadge(1);

              }

              if (i == response_cam.data.data.cameras.length - 1) {
                this.loading1=false;
              }
            }

            setTimeout(()=>{ this.loading1=false },5000);
          }).catch(() => {
            this.showError('Alert API!', `Problème du service API (GET CAM)!`)
            this.isDisabled=false
          });

        } else {
          this.showError('Alert API!', `Problème du service API (LOGUN)!`)
          this.isDisabled=false
        }
      }).catch(() => {
        this.showError('Alert Serveur!', `Serveur est inaccessible!`)
        this.isDisabled=false
      });


    },
    sendHTTP(action) {

      const opt = {
        httpsAgent: new https.Agent({
          rejectUnauthorized: false
        })
      }
      return axios.get(this.server + '/webapi/auth.cgi?api=SYNO.API.Auth&method=Login&version=' + this.version + '&account=' + this.login + '&passwd=' + this.pwd + '&session=SurveillanceStation&format=sid',opt).then(response => {
        if (response.status === 200) {
          let sid = response.data.data.sid;
          axios.get(this.server + '/webapi/entry.cgi?privCamType=3&version=' + this.nb_cam + '&blIncludeDeletedCam=false&streamInfo=false&api="SYNO.SurveillanceStation.Camera"&basic=true&method="List"&_sid=' + sid,opt).then(response_cam => {
            for (let i = 0; i < response_cam.data.data.cameras.length; i++) {
              let id_cam = response_cam.data.data.cameras[i].id;
              if (response_cam.data.data.cameras[i].enabled) {
                axios.get(this.server + '/webapi/entry.cgi?api=SYNO.SurveillanceStation.ExternalRecording&method=Record&version=' + this.ext_record + '&cameraId=' + id_cam + '&action=' + action + '&_sid=' + sid,opt)
                    .then(response_cam_action => {
                      if (response_cam_action.data.success) {
                        console.log(action, response_cam_action.data.success, response_cam.data.data.cameras[i].name);
                        if (i == response_cam.data.data.cameras.length - 1) {
                          if (action == 'start') {
                            this.started = setInterval(this.lockRunning, 10);
                            this.running = true;
                            this.isDisabled=false
                            navigator.setAppBadge(1);
                          } else {
                            clearInterval(this.started);
                            this.stoppedDuration = 0;
                            this.timeBegan = null;
                            this.timeStopped = null;
                            this.time = "00:00:00.000";
                            this.running = false;
                            this.isDisabled=false
                            navigator.clearAppBadge()
                          }
                        }
                      } else {
                        this.showError('Alert API!', `Problème du service API (Record fail start)!`)
                      }
                      if (i == response_cam.data.data.cameras.length - 1) {
                        axios.get(this.server + '/webapi/auth.cgi?api=SYNO.API.Auth&method=Logout&version=' + this.version + '&session=SurveillanceStation&_sid=' + sid,opt).then(response_logout => {
                          console.log('logout', response_logout.data.success);
                        });
                      }
                    }).catch((error) => {
                  console.log(error);
                  console.log('Alert API!', `Problème du service API ${response_cam.data.data.cameras[i].name} (Record)!`)
                });
              } else {
                this.showWarn('Alert !', `La caméra ${response_cam.data.data.cameras[i].name} est désactivé !`)
                this.isDisabled=false
              }
            }
          }).catch(() => {
            this.showError('Alert API!', `Problème du service API (GET CAM)!`)
            this.isDisabled=false
          });

        } else {
          this.showError('Alert API!', `Problème du service API (LOGUN)!`)
          this.isDisabled=false
        }
      }).catch(() => {
        this.showError('Alert Serveur!', `Serveur est inaccessible!`)
        this.isDisabled=false
      });


    },
    zeroPrefix(num, digit) {
      let zero = '';
      for (let i = 0; i < digit; i++) {
        zero += '0';
      }
      return (zero + num).slice(-digit);
    },
    startVideo() {
      this.isDisabled=true
      this.sendHTTP('start')


    },
    stopVideo() {
      this.isDisabled=true
      this.sendHTTP('stop')
    },

  }
}
</script>

<style scoped>
.card {
  text-align: center;
}

.btn-start {
  width: 150px !important;
  height: 150px !important;
  font-size: 2.5rem;
}

.time {
  font-size: 3em !important;
}

</style>
